.access_request {
  padding: 0.5em;
}

.access_request > div {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.confirmation_dialog {
  display: flex;
}

.confirmation_dialog .dialog_content {
  padding: 1em;
  display: flex;
  justify-content: center;
}

.confirmation_dialog .buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.access_request .decision {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
