.dialog .icon {
  cursor:pointer;
}

.dialog .glass {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #010;
  opacity: 0.7;
}

.dialog .chrome {
  display: flex;
  flex-direction: column;
  position:absolute;
  z-index: 20;
  top: 5em;
  left: 2em;
  right: 2em;
  background-color: #fff;
}

.dialog .chrome > header {
  display: flex;
  background-color: #4D4D4D;
  color: #fff;
  padding: 0.25em;
}

.dialog .chrome > header > h3 {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.dialog > header span {
  margin-left: 0.5em;
}

.dialog .content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  color: #000;
}
